<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.distribution') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <div class="row">
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="division_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.division_id"
                                                                    :options="divisionList"
                                                                    id="division_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :disabled="authLocation.division_id != 0"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="district_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.district_id"
                                                                    :options="districtList"
                                                                    id="district_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :disabled="authLocation.district_id != 0"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="upazilla_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.upazilla_id"
                                                                    :options="upazilaList"
                                                                    id="upazilla_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :disabled="authLocation.upazilla_id != 0"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="fiscal_year"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('dae_subsidy.fiscal_year')}} <span class="text-danger">*</span>
                                                                </template>
                                                                    <b-form-select
                                                                        plain
                                                                        v-model="formData.fiscal_year"
                                                                        :options="fiscalYearList"
                                                                        id="fiscal_year"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="circular_type"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('dae_subsidy.circular_ty')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.circular_type"
                                                                    :options="circularTypeList"
                                                                    id="circular_type"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="circular_id"
                                                                    slot-scope="{ valid, errors }">
                                                                    <template v-slot:label>
                                                                    {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-select
                                                                    plain
                                                                    v-model="formData.circular_id"
                                                                    :options="circularList"
                                                                    @change="getApplication"
                                                                    id="circular_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12" v-if="is_subsidy">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.stock_list') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th>{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th width="100px">{{ $t('dae_subsidy.quantity') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(stock,index) in formData.centralStockQtyInsList" :key="index">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ getItemName(stock.item_id) }}</b-td>
                                                            <b-td><input class="form-control" type="text" v-model="stock.quantity" readonly/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <p class="text-danger" v-if="is_warning"> {{'Distribution quantity and stock quantity not match or distribution is bigger than stock'}}</p>
                                        </div>
                                        <div class="col-sm-12 col-md-12" v-if="is_subsidy">
                                             <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.applicantlist') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.applicant') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.nid') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.application_date') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.quantity') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.approve') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(applicant,index) in formData.tmpApplicantList" :key="index">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ ($i18n.locale === 'bn') ? applicant.name_bn : applicant.name }}</b-td>
                                                            <b-td>{{ '0' + $n(applicant.mobile_no, { useGrouping: false }) }}</b-td>
                                                            <b-td>{{ $n(applicant.nid_no, { useGrouping: false }) }}</b-td>
                                                            <b-td>{{ ($i18n.locale === 'bn') ? applicant.instrument_name_bn : applicant.instrument_name }}</b-td>
                                                            <b-td>{{ applicant.created_at | dateFormat}}</b-td>
                                                            <b-td><input class="form-control" type="text" value="1" readonly/></b-td>
                                                            <b-td class="text-center"><input type="checkbox" v-model="applicant.is_checked"/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12" v-if="is_incentiveMeretial">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.stock_list') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.type') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th width="100px" class="text-center">{{ $t('dae_subsidy.quantity') }} <slot v-if="formData.circular_type === 2 || formData.circular_type === 3">{{ $t('dae_subsidy.kg') }}</slot></b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(stock,index) in formData.stockQtyCropMeterial" :key="index">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ getTypeName(stock.type) }}</b-td>
                                                            <b-td>{{ getCropMetName(stock.type, stock.item_id) }}</b-td>
                                                            <b-td><input class="form-control" type="text" v-model="stock.quantity" readonly/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                    <h5 style="font-weight: 500; border: 1px solid #999; padding: 5px; margin-bottom: 5px;">
                                                        {{ $t('dae_subsidy.allo_cash_amount') }}: {{ $n(upzAllocationAmount) }}
                                                    </h5>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12 mt-3" v-if="is_incentiveMeretial">
                                             <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.applicantlist') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2 table-responsive text-center">
                                                        <b-thead>
                                                            <b-tr>
                                                                <b-th rowspan="3" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_father_name') }}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.nid') }}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{$t('common_config.account_type')}}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{$t('common_config.account_no')}}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{$t('bsri_demandDIS.bank')}}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{$t('bsri_demandDIS.branch')}}</b-th>
                                                                <b-th rowspan="3" class="text-center">{{$t('dae_subsidy.cashAllocationTk')}}</b-th>
                                                                <b-th :colspan="policyCrops.length + policyMaterials.length">{{ $t('dae_grant_allocation_distribution.allocation') }} {{ $t('dae_grant_allocation_distribution.quantity') }}</b-th>
                                                                <b-th rowspan="3">{{ $t('globalTrans.approve') }}</b-th>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-th :colspan="policyCrops.length ? policyCrops.length : 1" >{{ $t('dae_grant_allocation_distribution.crop_name') }}</b-th>
                                                                <b-th :colspan="policyMaterials.length ? policyMaterials.length : 1">{{ $t('dae_grant_allocation_distribution.fertilizer') }}</b-th>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-th v-for="(pCrop,pCropIndex) in policyCrops" :key="pCropIndex">
                                                                {{ ($i18n.locale === 'bn') ? pCrop.crop_name_bn : pCrop.crop_name }}
                                                                </b-th>
                                                                <b-th v-for="(pMatr,pMatrIndex) in policyMaterials" :key="pMatrIndex">
                                                                {{ ($i18n.locale === 'bn') ? pMatr.material_name_bn : pMatr.material_name }}
                                                                </b-th>
                                                            </b-tr>
                                                        </b-thead>
                                                        <b-tbody>
                                                            <b-tr v-for="(detail,index) in formData.applicant_details" :key="index">
                                                                <b-td>{{ $n(index+1) }}</b-td>
                                                                <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                                                                <b-td>{{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}</b-td>
                                                                <b-td>{{ $n(detail.nid_no, { useGrouping: false }) }}</b-td>
                                                                <b-td>{{ ($i18n.locale == 'bn' ? '০': '0') + $n(detail.mobile_no, { useGrouping: false } ) }}</b-td>
                                                                <b-td>{{ detail.account_type_name }}</b-td>
                                                                <b-td>{{ detail.farmer_data.ac_category_id == 1 ? detail.farmer_data.wallet_no : detail.farmer_data.account_no }}</b-td>
                                                                <b-td>{{ detail.farmer_data.ac_category_id == 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                                                <b-td>{{ detail.branch_name }}</b-td>
                                                                <b-td class="text-right">{{ allocationAmount }}</b-td>
                                                                <b-td v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="sinPCropIndex" class="text-center">
                                                                    {{ getCropNameData(sinPCrop,detail.demand_crop_approval) }}
                                                                </b-td>
                                                                <b-td v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="sinPMaterialIndex" class="text-center">
                                                                    {{ getMeterialNameData(sinPMaterial,detail.demand_material_approval) }}
                                                                </b-td>
                                                                <b-td class="text-center"><input type="checkbox" v-model="detail.is_checked"/></b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                        <b-tfoot style="font-weight:bold">
                                                            <b-tr>
                                                                <b-td colspan="9" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                                                <b-td class="text-right">{{ $n(grandTotal) }}</b-td>
                                                                <b-td v-for="(cropItem, cropItemIndex) in totalRowItem.crops" :key="cropItemIndex+300" class="text-center">
                                                                    {{ cropItem !== 0 ? $n(cropItem) : '-' }} {{ cropItem !== 0 ? $t('globalTrans.kg') : '' }}
                                                                </b-td>
                                                                <b-td v-for="(matItem, matItemIndex) in totalRowItem.materials" :key="matItemIndex+300" class="text-center">
                                                                    {{ matItem !== 0 ? $n(matItem) : '-' }} {{ matItem !== 0 ? $t('globalTrans.kg') : '' }}
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tfoot>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Date" vid="distribution_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="distribution_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('dae_subsidy.distribution_data') }}
                                                </template>
                                                <b-form-input
                                                    class="fromDate"
                                                    id="distribution_date"
                                                    v-model="formData.distribution_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :placeholder="$t('dae_subsidy.select_date')"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (En)" vid="place" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (Bn)" vid="place_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <p style="font-weight:500">[{{ $t('dae_subsidy.distributed_note') }}]</p>
                                        </div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                <router-link variant="success" to="distribution">    &nbsp;
                                                <b-button variant="danger" class="mr-1">{{ $t('globalTrans.cancel') }}</b-button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionStore, getApplicationDataApi } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
        }
      this.setAuthLocationInfo()
    },
    data () {
        return {
            grandTotal: 0,
            loading: false,
            isStock: false,
            fiscalYear: '',
            formData: {
                fiscal_year: 0,
                circular_type: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                circular_id: 0,
                place: '',
                place_bn: '',
                distribution_date: '',
                tmpApplicantList: [],
                applicant_details: [],
                centralStockQtyInsList: [],
                stockQtyCropMeterial: []
            },
            circularList: [],
            districtList: [],
            upazilaList: [],
            policyCrops: [],
            policyMaterials: [],
            grandLandTotal: 0,
            is_subsidy: false,
            is_incentiveMeretial: false,
            is_warning: false,
            is_item: true,
            is_stock: true,
            is_permission: true,
            validationResult: {
                success: true,
                message: ''
            },
            authLocation: {
              division_id: 0,
              district_id: 0,
              upazilla_id: 0
            },
            upzAllocationAmount: 0,
            allocationAmount: 0,
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ],
            totalRowItem: {
                crops: {},
                materials: {}
            }
        }
    },
    watch: {
        'formData.circular_type': function (newValue, oldVal) {
            this.circularList = this.getCircularName()
            if (newValue === 1) {
                this.formData.applicant_details = []
                this.is_subsidy = true
                this.is_incentiveMeretial = false
            }
            if (newValue !== 1) {
                if (newValue !== oldVal) {
                    this.formData.applicant_details = []
                }
                this.formData.tmpApplicantList = []
                this.is_subsidy = false
                this.is_incentiveMeretial = true
            }
        },
        'formData.fiscal_year': function () {
            this.circularList = this.getCircularName()
        },
        'formData.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'formData.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
        saveBtnName: function () {
            return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
        },
        locale: function () {
            return this.$i18n.locale
        },
        divisionList () {
            const list = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
            return list.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        circularTypeList () {
            const data = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন' }
            ]
            return data
        },
        typeList () {
            const data = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Crop' : 'শস্য' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Material' : 'উপাদান' }
            ]
            return data
        },
        fiscalYearList () {
            return this.$store.state.commonObj.fiscalYearList
        }
    },
    methods: {
        getFiscalYear (fiscalyear) {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === fiscalyear)
            if (this.$i18n.locale === 'bn') {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text_bn }
            } else {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text }
            }
        },
        async getApplication () {
            if (this.formData.circular_id !== 0) {
                this.loading = true
                const params = {
                    fiscal_year_id: this.formData.fiscal_year,
                    division_id: this.formData.division_id,
                    district_id: this.formData.district_id,
                    upazilla_id: this.formData.upazilla_id,
                    circular_type: this.formData.circular_type,
                    circular_id: this.formData.circular_id
                }
                await RestApi.getData(incentiveGrantServiceBaseUrl, getApplicationDataApi, params)
                .then(response => {
                    if (response.success) {
                        if (this.formData.circular_type === 1) {
                            this.formData.centralStockQtyInsList = response.data.centralStockQtyInsList
                            this.formData.tmpApplicantList = response.data.application.map(item => {
                                const check = { is_checked: true }
                                return Object.assign({}, item, check)
                            })
                        }
                        if (this.formData.circular_type !== 1) {
                            this.upzAllocationAmount = response.upzAllocationAmount
                            this.formData.stockQtyCropMeterial = response.stockQtyCropMeterial
                            this.policyCrops = response.existCropList
                            this.policyMaterials = response.existMaterialList
                            this.policyCrops.forEach(item => {
                                this.totalRowItem.crops[item.crop_id] = 0
                            })
                            this.policyMaterials.forEach(item => {
                                this.totalRowItem.materials[item.material_id] = 0
                            })
                            const appList = response.data.map(item => {
                                const check = { is_checked: true }
                                return Object.assign({}, item, check)
                            })

                            this.allocationAmount = response.allocationAmount
                            this.formData.applicant_details = this.getDemandRelatinalData(appList)
                            this.grandTotal = parseFloat(appList.length * response.allocationAmount)
                        }
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: response.message,
                            color: '#fff'
                        })
                    }
                })
                this.loading = false
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Please , Select valid circular',
                    color: '#fff'
                })
                this.formData.tmpApplicantList = []
                this.formData.applicant_details = []
                this.$bvModal.hide('modal-4')
            }
        },
        getDemandRelatinalData (data) {
            const listData = data.map((item) => {
                const deReqObj = {
                    far_general_info_id: item.far_general_info_id,
                    subs_demand_id: item.id,
                    land_quantity: item.land_quantity,
                    farmer_name: item.farmer_data.name,
                    farmer_name_bn: item.farmer_data.name_bn,
                    father_name: item.farmer_data.father_name,
                    father_name_bn: item.farmer_data.father_name_bn,
                    nid_no: item.farmer_data.nid_no,
                    mobile_no: item.farmer_data.mobile_no,
                    demandCollectioCropList: item.demand_collection_details,
                    crops: [],
                    material: []
                }
                this.grandLandTotal += item.land_quantity ? (parseFloat(item.land_quantity)) : 0
                this.policyCrops.map(tmp => {
                    deReqObj.crops.push({ id: tmp.crop_id, quantity: tmp.quantity })
                    this.totalRowItem.crops[tmp.crop_id] += this.getCropQnty(tmp, item.demand_crop_approval)
                })
                this.policyMaterials.map(tmpMt => {
                    deReqObj.material.push({ id: tmpMt.material_id, quantity: tmpMt.quantity })
                    this.totalRowItem.materials[tmpMt.material_id] += this.getMaterialQnty(tmpMt, item.demand_material_approval)
                })
                const accountTypeData = this.accountTypeList.find(type => type.value === item.farmer_data.ac_category_id)
                const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
                const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.farmer_data.ac_type_id)
                const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
                const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.farmer_data.bank_id)
                const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
                const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.farmer_data.branch_id)
                const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
                return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
            })
            return listData
        },
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        validationCheck () {
            if (this.formData.circular_type === 1) {
                const isCheckCount = this.formData.tmpApplicantList.filter(item => item.is_checked === true)
                if (isCheckCount.length !== 0) {
                    let tm = []
                    this.formData.tmpApplicantList.forEach(appItem => {
                        if (appItem.is_checked === true) {
                            const stockItem = this.formData.centralStockQtyInsList.find(stockItem => stockItem.item_id === parseInt(appItem.instrument_id))
                            if (typeof stockItem === 'undefined') {
                                this.is_item = false
                            } else {
                                if (tm.length === 0) {
                                    const dd = Object.assign(appItem, { quantity: 1 })
                                    tm.push(dd)
                                } else {
                                    const ntm = tm.find(itm => parseInt(itm.instrument_id) === parseInt(appItem.instrument_id))
                                    if (typeof ntm === 'undefined') {
                                        const rr = Object.assign(appItem, { quantity: 1 })
                                        tm.push(rr)
                                    } else {
                                        const datas = []
                                        tm.forEach(i => {
                                            if (parseInt(i.instrument_id) === parseInt(ntm.instrument_id)) {
                                                const quantity = parseInt(i.quantity) + 1
                                                datas.push(Object.assign(i, { quantity: quantity }))
                                            }
                                        })
                                        tm = datas
                                    }
                                }
                            }
                        }
                    })
                    if (tm.length > 0) {
                        tm.map(item => {
                            const stockItm = this.formData.centralStockQtyInsList.find(stock => stock.item_id === item.instrument_id)
                            if (item.quantity > stockItm.quantity) {
                                this.is_stock = false
                            }
                        })
                    }
                } else {
                    this.is_permission = false
                }
            }
            if (this.formData.circular_type !== 1) {
              this.checkCropMaterialStock()
            }
        },
        getCropQnty (singleCrop, detail) {
            const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : 0
        },
        getMaterialQnty (mat, detail) {
            const objectData = detail.find(item => item.material_id === mat.material_id)
            return objectData !== undefined && objectData.quantity ? objectData.quantity : 0
        },
        checkCropMaterialStock () {
            /** Field Vailidation check */
            const filteredFarmers = this.formData.applicant_details.filter(item => item.is_checked === true)
            if (filteredFarmers.length === 0) {
                this.validationResult.success = false
                this.validationResult.message = 'Please, select at least one applicant to distribute'
            }
            /** checking item exist */
            try {
              this.validateCropStock(filteredFarmers)
              this.validateMaterialStock(filteredFarmers)
            } catch (e) {
              return e
            }
        },
        validateCropStock (filteredFarmers) {
          const cropWiseTotalQty = {}
          filteredFarmers.forEach(item => {
            const approvedCrops = item.demand_crop_approval
            const stockCropMaterial = this.formData.stockQtyCropMeterial
            approvedCrops.forEach(cropItem => {
                const stockDetail = stockCropMaterial.find(stockItem => stockItem.type === 2 && stockItem.item_id === cropItem.crop_id)
                /** checking item exist */
                if (stockDetail === undefined) {
                    this.validationResult.success = false
                    this.validationResult.message = 'Crop item not available in stock'
                    throw this.validationResult
                }
                cropWiseTotalQty[cropItem.crop_id] = cropWiseTotalQty[cropItem.crop_id] !== undefined ? (cropWiseTotalQty[cropItem.crop_id] + cropItem.quantity) : cropItem.quantity
            })
            /** Checking Stock */
            for (const property in cropWiseTotalQty) {
              const stock = stockCropMaterial.find(stockItem => stockItem.type === 2 && stockItem.item_id === parseInt(property))
              if (cropWiseTotalQty[property] > stock.quantity) {
                this.validationResult.success = false
                this.validationResult.message = 'Distributed quantity is bigger stock'
                throw this.validationResult
              }
            }
          })
        },
        validateMaterialStock (filteredFarmers) {
          const meterialWiseTotalQty = {}
          filteredFarmers.forEach(item => {
            const approvedMeterials = item.demand_material_approval
            const stockCropMaterial = this.formData.stockQtyCropMeterial
            approvedMeterials.forEach(materialItem => {
                const stockDetail = stockCropMaterial.find(stockItem => stockItem.type === 3 && stockItem.item_id === materialItem.material_id)
                /** checking item exist */
                if (stockDetail === undefined) {
                    this.validationResult.success = false
                    this.validationResult.message = 'Material item not available in stock'
                    throw this.validationResult
                }
                meterialWiseTotalQty[materialItem.material_id] = meterialWiseTotalQty[materialItem.material_id] !== undefined ? (meterialWiseTotalQty[materialItem.material_id] + materialItem.quantity) : materialItem.quantity
            })
            /** Checking Stock */
            for (const property in meterialWiseTotalQty) {
              const stock = stockCropMaterial.find(stockItem => stockItem.type === 3 && stockItem.item_id === parseInt(property))
              if (meterialWiseTotalQty[property] > stock.quantity) {
                this.validationResult.success = false
                this.validationResult.message = 'Distributed quantity is bigger stock'
                throw this.validationResult
              }
            }
          })
        },
        async saveData () {
          this.validationResult.success = true
          this.validationResult.message = ''
            this.validationCheck()
            if (!this.validationResult.success) {
                this.displayError(this.validationResult.message)
                return
            }
            if (this.is_item === true && this.is_stock === true && this.is_permission === true) {
                this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                const loadingState = { loading: false, listReload: false }
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionStore, this.formData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.loading = false
                if (result.success) {
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.$router.push('/incentive-grant-service/dae/subsidy/distribution')
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#fff'
                    })
                }
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Item not found in stock or bigger than stock',
                    color: '#fff'
                })
                this.is_item = true
                this.is_stock = true
                this.is_permission = true
            }
        },
        displayError (message) {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: message,
                color: '#fff'
            })
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getCircularName () {
            const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.fiscal_year_id === this.formData.fiscal_year && item.type === this.formData.circular_type)
            return circularList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getItemName (itemId) {
            const insList = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === itemId)
            if (this.$i18n.locale === 'bn') {
                return insList.text_bn
            } else {
                return insList.text_en
            }
        },
        getCropName (cropId) {
            const objectData = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === cropId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        getTypeName (type) {
            const objectData = this.typeList.find(item => item.value === type)
            return objectData.text
        },
        getCropMetName (type, items) {
            if (type === 2) {
                const objectData = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === items)
                if (this.$i18n.locale === 'bn') {
                    return objectData.text_bn
                } else {
                    return objectData.text_en
                }
            }
            if (type === 3) {
                const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(item => item.value === items)
                if (this.$i18n.locale === 'bn') {
                    return objectData.text_bn
                } else {
                    return objectData.text_en
                }
            }
        },
        getCropNameData (singleCrop, detail) {
            const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
            return objectData !== undefined ? objectData.quantity + this.$t('dae_grant_allocation_distribution.kg') : '-'
        },
        getMeterialNameData (singleMetrial, details) {
            const objectData = details.find(item => item.material_id === singleMetrial.material_id)
            return objectData !== undefined ? objectData.quantity + this.$t('dae_grant_allocation_distribution.kg') : '-'
        },
        setAuthLocationInfo () {
          let location = {
            division_id: 0,
            district_id: 0,
            upazilla_id: 0
          }
          const authUser = this.$store.state.Auth.authUser
          if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
            const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
            if (typeof office !== 'undefined') {
              location = {
                division_id: office.division_id,
                district_id: office.district_id,
                upazilla_id: office.upazilla_id
              }
            }
          }
          this.formData = Object.assign({}, this.formData, location)
          this.authLocation = Object.assign({}, this.authLocation, location)
        }
    }
}
</script>
